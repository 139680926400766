#AboutUs {
  position: relative;
  padding-bottom : 150px;
  &-body {
    position: relative;
    z-index: 3;
    opacity: 1;
    padding-top: 150px;
    @include width;
    margin: auto;
    @include typoBody;
    color: $darkGrey;
    font-weight: normal;
    h1 {
      color: $lightBlue;
      @include h1Title;
      font-size: 2em;
    }

    h2 {
      @include h2subTitle color: $lightRed;
      font-weight: bold;
    }

    a {
      @include primaryButton;
      display: inline-table;
      width: auto; 
      margin-top: 40px;
      background: $lightRed;
    }
    .BottomPricing {
      width: 100vw;
      position: absolute;
      bottom: 0;
    }
  }
}
