#nav {
  .navigation-box {
    position: fixed;
    z-index: 90;
    left: 0;
    right: 0;

    transition: margin 1s ease-in-out;
    margin-top: 40px;
    &.notOnTop {
      margin-top: 20px;
    }

    .holder-nav {
      // n is the container below the navigation
      max-width: 1400px;
      @include width;
      border-radius: 90px;
      margin: auto;
      background: $white;
      -webkit-box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.75);
      position: relative;

      .switch-language {
        position: absolute;
        bottom: 0;
        height: 100%;
        display: flex;
        width: 40px;
        z-index: 90;
        font-family: $title;
        justify-content: center;
        border-radius: 0px 50% 50% 0;
        align-items: center;
        flex-direction: column;
        color: $white;
        left: calc(100% - 25px);
        background: $darkBlue;
        cursor: pointer;
      }

      .nav {
        a {
          text-decoration: none;
          &.active {
            display: none;
          }
          color: inherit;
        }
        font-family: $title;
        color: $darkBlue;
        height: 50px;
        margin: auto;
        width: 95%;
        display: flex;
        justify-content: space-between;

        // nav-left
        &-left {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          cursor: pointer;

          &-logo {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-height: 42px;
            }
          }
          &-label {
            margin-left: 15px;
            font-size: 28px;
            line-height: 42px;
            font-family: $title;
          }
        }

        // nav-right
        &-right {
          border-radius: 0px ;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          line-height: 24px;
          position: relative;
          z-index: 99;
          padding-right: 20px;
          background: $white;
          &-sub {
            &:hover {
              transform: translateY(-2px);
              transition: transform 0.2s ease-in-out;
            }
            font-size: 16px;
            @media only screen and (max-width: 1400px) {
              font-size: 14px;
            }
            @media only screen and (max-width: 1200px) {
              font-size: 12px;
            }
            margin-left: 15px;
            cursor: pointer;

            &.sign-in {
              padding: 5px 20px;
              background: $darkBlue;
              color: $white;
              border-radius: 5px;
              letter-spacing: 2.5px;
              //-webkit-box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.5);
              //-moz-box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.5);
              //box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.5);
              &:hover {
                transform: translateY(-2px);
                transition: transform 0.2s ease-in-out;
              }
            }
          }
        }
      }
    }
  }
  #m-toggle-nav {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .desktop {
      display: none;
    }
    .m-navigation-box {
      opacity: 1;
      display: flex;
      background: linear-gradient(
          to top,
          rgba($lightBlue, 0.9),
          rgba($darkBlue, 0.7)
        ),
        url("https://images.pexels.com/photos/2179483/pexels-photo-2179483.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
      background-size: cover;
      width: 100%;
      height: 100%;
      .m-holder-nav {
        margin: auto;
        height: 80%;
        width: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        * {
        }
        .m-nav-up {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          color: $white;
          font-size: 48px;
          .nav-left-label {
            font-family: $title !important;
            @include sectionTitle;
          }
          img {
            padding: 2px;
            border-radius: 50%;
            background: $white;
            max-width: 48px;
            margin-right: 20px;
          }
        }
        .m-nav-down {
          * {
          }
          display: flex;
          justify-content: space-around;
          align-items: space-around;
          flex-direction: column;
          height: 60%;
          .m-nav-right-sub {
            @include sectionSubTitle;
            font-size: 34px;
            color: $white;
            padding: 10px 50px;
            text-align: center;
          }
          .sign-in {
            background: white;
            color: $darkBlue;
            text-align: center;
            border-radius: 15px;
          }
        }
        .switch-language {
          height: 20px;
          span {
            color : rgba($darkGrey, .8);
          }
        }
      }
    }
    #m-toggle-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 9;
      display: flex;
      height: 50px;
      width: 50px;
      background: $white;
      position: fixed;
      top: 20px;
      right: 20px;
      border-radius: 50%;
      padding: 5px;
      rect {
        transition: 0.5s ease-in-out;
      }
      svg {
        margin: 5px;
        width: 90%;
      }
    }
  }
}
#m-navigation-holder {
  z-index: 8;
  bottom: -100%;
  position: fixed;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  border-radius: 0px;
  right: 0;
  transition: 0.6s ease-in-out;
  &.show {
    bottom: 0;
    opacity: 1;
  }
}
