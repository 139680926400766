#Pricing {
  padding-bottom: 40px;
  z-index: 2;
  position: relative;
  .price-central {
    margin: auto;
    width: 90%;
    max-width: 1200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    .price-card {
      background: $white;
      min-width: 200px;
      margin-top: 100px;
      transition: transform 0.5s ease-in-out;
      cursor: default;
      &:hover {
        transform: translateY(-5px);
      }

      position: relative;
      @include shadow($darkGrey);
      padding: 40px 40px;
      padding-bottom: 20px;
      border-radius: 15px;

      .price-title {
        position: relative;
        @include sectionTitle;
        color: $darkGrey;
        margin-bottom: 45px;
        .sub-title {
          position: absolute;
          top: 80%;
          left: 10px;
          @include typoBody;
        }
      }
      .price-funct {
        padding: 0;
        @include typoBody;
        font-weight: lighter;
        line-height: 1.6em;
      }
      .funct {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        &.nok {
          opacity: 0.5;
        }

        line-height: 30px;
        img {
          width: 25px;
          margin-right: 5px;
          border-radius: 50%;
        }
      }
      .price-cta {
        width: 100%;
        margin-top: 40px;
        text-align: right;
        a {
          @include primaryButton;
          background: $darkBlue;
          padding: 8px 20px;
          &::nth-child(2) {
            background: $lightRed;
          }
        }
      }
      .price-tag {
        position: absolute;
        top: 25%;
        left: 75%;
        .price-tag-inside {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .price-tag-price {
            z-index: 4;
            color: $white;
            position: relative;
            span {
              font-size: 16px;
            }
            &:after {
              content: "€";
              font-size: 16px;
              position: absolute;
              bottom: 5px;
              right: -10px;
            }
          }
          svg {
            width: 120px;
            height: 100px;
            position: absolute;
            opacity: 0.9;
          }
        }
      }
      .price-label {
        position: absolute;
        top: -10px;
        right: 20px;
        font-size: 15px;
        background: $lightRed;
        padding: 2px 15px;
        border-radius: 5px;
        color: $white;
        font-weight: 900;
        letter-spacing: 1.1px;
        font-family: $normal;
      }
      &.central {
        .price-cta a {
          background: $lightRed;
        }
      }
    }
  }
}
.BottomPricing {
  height: auto;
  left: 0;
  right: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
}
