#header {
  width: 100%;
  height: 80vh;
  @media only screen and (min-width: 1024px) {
    min-height: 100vh;
  }
  @media only screen and (max-width: 1024px) {
    height: auto;
  }

  .bg-img {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    background: linear-gradient(to top, rgba(blue, 0.4), rgba(#6991c7, 0.7)),
      url("https://images.pexels.com/photos/2179483/pexels-photo-2179483.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
    background-size: cover;
    .svg-header {
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100vw;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .header-central {
    @include width;
    max-width: 1600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    @media only screen and (max-width: 1024px) {
      padding-top: 100px;
      justify-content: stretch;
      align-items: center;
      flex-wrap: wrap;
    }
    margin: auto;
    z-index: 2;
    .header-left {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }

      &-title {
        @include h1Title;
        color: $white;
      }
      small {
        color: #c7c7c7;
        font-size: 0.5em;
      }

      &-body {
        @include h2subTitle;
        max-width: 80%;
      }

      &-cta {
        margin-top: 20px;
        position: relative;
        padding-top: 10px;
        .btn {
          text-decoration: none;
          @include primaryButton;

          &-get-started {
            margin-right: 20px;
            border: solid 3px $white;
            color: $lightBlue;
            background: $white;
          }
        }
        .no-card-required {
          color: $white;
          opacity: 0.8;
          position: absolute;
          top: 135%;
          left: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 10px;
            top: 150%;
          }
        }
      }
    }

    .header-right {
      max-width: 50%;
      position: relative;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      .illu-laptop-header {
        height: 100%;
        max-width: 50vw;
        max-height: 800px;
        @media only screen and (max-width: 1024px) {
          max-height: 400px;
          max-width: 100vw;
          min-width: 400px;
        }
      }
      .illu-phone-header {
        max-height: 70%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
} // style is ok

@media only screen and (max-width: 1024px) {
} // style is ok

.scrollDown {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  &.onTop {
    display: inherit;
  }
  &.hasScrolled {
    display: none;
  }
}

.scrollDown span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 4px solid $darkBlue;
  border-right: 4px solid $darkBlue;
  @media (max-width: 1024px) {
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
  }
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 3s infinite;
}

.scrollDown span:nth-child(2) {
  animation-delay: -0.4s;
}

.scrollDown span:nth-child(3) {
  animation-delay: -0.8s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-30px, -30px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(10px, 10px);
  }
}
