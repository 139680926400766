
$white: #ffffff;

$lightBlue: #1890ff;
$lightRed: #ff9a9a;
$lightPurple: #9470ab;

$mediumBlue: #0767c0;

$darkBlue: #265e98;
$darkGrey: #707070;

$title: "Varela Round";
$normal: "Roboto";

@mixin shadow($color) {
  -webkit-box-shadow: 0px 6px 24px 0px rgba($color, 0.15);
  -moz-box-shadow: 0px 6px 24px 0px rgba($color, 0.15);
  box-shadow: 0px 6px 24px 0px rgba($color, 0.15);
}

@mixin width {
  width: 60%;
  @media (max-width: 1400px) {
    width: 80%;
  }
  @media (max-width: 700px) {
    width: 95%;
  }
}

@mixin pleft {
  //padding-left: calc((100% - #{$wcentral}) / 2);
  padding-left: 20%;
  @media (max-width: 1600px) {
    padding-left: 10%;
  }
  @media (max-width: 1400px) {
    padding-left: 5%;
  }
  @media (max-width: 1024px) {
    padding-left: 5%;
  }
}

@mixin h1Title {
  font-family: $title;
  font-size: 50px;
  @media only screen and (max-width: 1400px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 24px;
  }
  line-height: 30px;
  font-weight: bold;
}

@mixin h2subTitle {
  text-transform: capitalize;
  font-family: $normal;
  font-size: 26px;
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
  color: $white;
  font-weight: lighter;
  letter-spacing: 1.5px;
  //text-shadow: 0px 0px 10px $white;
  //font-weight: bold;
}

@mixin sectionTitle {
  text-transform: capitalize;
  font-family: $title;
  font-size: 44px;
  @media only screen and (max-width: 1600px) {
    font-size: 34px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 30px;
  }
  font-weight: bold;
  margin-bottom: 20px;
}

@mixin sectionSubTitle {
  text-transform: capitalize;
  font-family: $title;
  font-size: 32px;
  @media only screen and (max-width: 1400px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 22px;
  }
  font-weight: bold;
  margin-bottom: 4px;
}

@mixin typoBody {
  font-family: $normal;
  font-weight: bold;
  font-size: 22px;
  @media only screen and (max-width: 1400px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 16px;
  }
}

@mixin primaryButton {
  font-family: $normal;
  text-decoration: none;
  padding: 6px 30px;
  font-weight: bold;
  font-size: 24px;
  @media only screen and (max-width: 1400px) {
    padding: 8px 30px;
    font-size: 22px;
  }
  @media only screen and (max-width: 1200px) {
    padding: 4px 30px;
    font-size: 18px;
  }
  border-radius: 3px;
  border: 0;
  letter-spacing: 2px;
  cursor: pointer;

  @include shadow($white);
  transition: transform 0.1s ease-in-out;
  background: $lightBlue;
  color: $white;

  &:hover {
    transform: translateY(-2px);
  }
}
