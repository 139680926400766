//@import '~antd/dist/antd.css';
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@import "./style/MixinColor.scss";
@import "./style/Navigation.scss";

// Landing
@import "./style/Header.scss";
@import "./style/HighlightFeat.scss";
@import "./style/UseCases.scss";
@import "./style/GetStarted.scss";
@import "./style/Features.scss";
@import "./style/Pricing.scss";
@import "./style/Footer.scss";


// UseCase 
@import "./style/UseCasePage.scss";

// AboutUs 
@import "./style/AboutUsPage.scss";

* {
  box-sizing: border-box;
  font-family: $normal;
  font-color: $darkGrey;
}

#root,
body {
  overflow-x: hidden;
  width: 100%;
}

.page-holder.menuOpen {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}
