#hightlightfeat {
  @media only screen and (max-width: 1024px) {
    padding-top: 100px;
  }
  width: 100%;
  .hf-central {
    margin: auto;

    .hf-title {
      @include pleft;
      margin: auto;
      @include sectionTitle;
      color: $darkBlue;
    }

    .hf-feats {
      margin: auto;
      max-width: 1200px;
      @media (max-width: 1400px) {
        width: 80%;
      }
      @media (max-width: 700px) {
        width: 95%;
      }
      margin-top: 50px;
      @media only screen and (max-width: 1024px) {
        margin-top: 0px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      .hf-feat {
        cursor: pointer;
        height: 100%;
        width: 33%;
        @media only screen and (max-width: 1024px) {
          width: 50%;
          margin-bottom: 100px;
        }
        @media only screen and (max-width: 724px) {
          width: 100%;
          margin-bottom: 150px;
        }

        padding: 5px 10px;
        &-title {
          z-index: 4;
          @include sectionSubTitle;
          opacity: 0.99;
        }
        &-body {
          z-index: 4;
          @include typoBody;
          color: $darkGrey;
          opacity: 0.99;
        }
        position: relative;
        .hf-feat-goo {
          z-index: 1;
          opacity: 0.5;
          position: absolute;
          transition: 2s ease-in-out;
          .largeGoo {
            transition: 3s ease-in-out;
          }
          .smallGoo {
            transition: 1.5s ease-in-out;
          }
        }

        // falert
        &.falert {
          .hf-feat-icon {
            position: absolute;
            width: 50%;
            height: auto;
            top: 30%;
            left: 0;
            opacity: 0.5;
          }
          .hf-feat-title {
            padding-top: 15px;
            padding-left: 15px;
          }
          .hf-feat-body {
            padding-left: 35px;
          }
          &:hover svg.hf-feat-goo {
            transform: translateX(15px);
            .smallGoo {
              transform: translate(10px, 15px), scale(1.1);
              d: path(
                "M83.004 297.081c51.064-28.37 18.909-68.256 4.583-74.413s-20.132-11.935-48.766-.21-41.174 35.868-18.464 67.19 45.674 9.54 62.647 7.433z"
              );
              .largeGoo {
                d: path(
                  "M243.22 303.063C339.7 314.237 300.9 84.192 260.984 50.954s-118.346-29.917-169.331.499-52.132 117.709-6.465 123.782c49.265 6.55 61.552 116.654 158.032 127.828z"
                );
              }
            }
          }
          .hf-feat-goo {
            top: -20%;
            left: -15%;
            width: 100%;
            height: auto;
          }
        }

        //fseo
        &.fseo {
          overflow: visible;
          .hf-feat-icon {
            position: absolute;
            width: 40%;
            right: 10%;
            height: auto;
            top: 0;
            opacity: 0.5;
          }
          .hf-feat-title {
            padding-top: 51px;
          }
          .hf-feat-body {
          }
          &:hover svg.hf-feat-goo {
            transform: translateX(15px);
            .smallGoo {
              transform: translate(10px, 15px), rotate(60deg);
              d: path(
                "M376.254 83.089c18.162-179.258-169.135-31.158-285.449 94.598S292.55 354.762 335.748 308.801s31.863-116.431 40.506-225.712z"
              );
            }
            .largeGoo {
              d: path(
                "M154.34 36.95c-37.84 7.04-84.122 28.92-106.278 69.002S35.038 211.924 65.72 197.276c33.098-15.8 126.459-167.365 88.62-160.325z"
              );
            }
          }
          .hf-feat-goo {
            top: -50%;
            left: -20%;
            width: 100%;
            height: auto;
          }
        }

        //fts
        &.fts {
          .hf-feat-icon {
            position: absolute;
            width: 40%;
            right: 10%;
            height: auto;
            top: 0;
            opacity: 0.5;
          }
          .hf-feat-title {
            padding-top: 51px;
          }
          .hf-feat-body {
          }
          &:hover svg.hf-feat-goo {
            transform: translateX(15px);
            .smallGoo {
              transform: translate(10px, 15px), rotate(60deg);
              d: path(
                "M193.742 216.393c-96.9-106.068-61.427-169.035-98.307-175.06-25.668-4.193-63.228 32.5-82.745 82.6C4.246 145.61-.801 169.962.105 194.544c2.979 80.86 55.751 170.232 107.246 170.232s183.292-42.316 86.391-148.383z"
              );
              .largeGoo {
                d: path(
                  "M286.903 212.745c88.77-1.767 1.609-106.333-38.866-140.996s-127.602-37.287-123.022 2.337c4.942 42.742 73.117 140.426 161.888 138.659zM219.076 387.503c26.105 2.035 60.37-4.193 81.987-26.63s29.416-112.49-35.816-122.527c-31.989 1.881-3.7 19.209-16.934 47.669-17.38 37.372-44.17 100.323-29.237 101.488z"
                );
              }
            }
          }
          .hf-feat-goo {
            opacity: 0.5;
            top: -20%;
            left: 15%;
            width: 80%;
            height: auto;
          }
        }

        //fhelp
        &.fhelp {
          .hf-feat-icon {
            position: absolute;
            width: 70%;
            left: 10%;
            height: auto;
            top: -40%;
            opacity: 0.3;
          }
          .hf-feat-title {
          }
          .hf-feat-body {
          }
          &:hover svg.hf-feat-goo {
            transform: translateX(15px);
            .smallGoo {
              transform: translate(10px, 15px), rotate(60deg);
              d: path(
                "M124.808 1.199C-12.391-16.178-36.238 158.971 52.097 287.623c17.471 25.448 137.813 48.033 224.975 43.458 57.41-3.013 100.39-17.9 89.455-51.686-5.042-14.057 4.734-197.082-241.719-278.196z"
              );
              .largeGoo {
                d: path(
                  "M232.633 68.07c32.159-7.804 101.905-28.891 120.693.014s10.95 93.708-15.109 91.712c-86.888 8.378-137.742-83.921-105.584-91.727z"
                );
              }
            }
          }
          .hf-feat-goo {
            opacity: 0.5;
            top: -20%;
            left: 15%;
            width: 80%;
            height: auto;
          }
        }
      }
    }
  }
}
