#Features {
  @include width;
  height: 100%;
  margin: auto;
  position: relative;
  // svgs
  .svg-1-features {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -15px;
    z-index: 4;
    right: -15px;
    opacity: 0.7;
    transition: all 4s ease-in-out;
  }

  .svg-2-features {
    width: 250px;
    height: 150px;
    position: absolute;
    bottom: -75px;
    left: -15px;
    opacity: 0.7;
    path {
      fill: $lightRed;
    }
  }

  .svg-3-features {
    width: 250px;
    height: 150px;
    position: absolute;
    top: 100%;
    right: 0;
    opacity: 0.7;
  }
  .ft-title {
    @include sectionTitle;
    color: $darkBlue;
  }
  .ft-section {
    margin-bottom: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 50px 0;
    @media only screen and (max-width: 1400px) {
      margin-bottom: 150px;
    }
    @media only screen and (max-width: 1024px) {
      flex-wrap: wrap;
      margin-bottom: 40px;
    }

    .ft-text {
      z-index: 5;
      width: 40%;
      @media only screen and (max-width: 1024px) {
        order: 1;
        width: 100%;
      }

      .ft-text-title {
        @include sectionSubTitle;
        color: $darkGrey;
      }
      .ft-text-desc {
        position: relative;
        margin-top: 20px;
        max-width: 400px;
        @include typoBody;
      }
      .grid-svg-features-alert {
        opacity: 0.4;
        position: absolute;
        transform: rotate(90deg);
      }
    }

    .ft-img-box {
      position: relative;
      width: 50%;
      @media only screen and (max-width: 1024px) {
        order: 2;
        width: 100%;
      }
      .support-img {
        &.seo-back-img,
        &.troubleshooting-back,
        &.slack-app {
          @media only screen and (max-width: 1024px) {
            display: none;
          }
          z-index: 1;
          position: absolute;
          top: -15%;
          left: -15%;
          height: 99%;
          filter: blur(1px);
          border-radius: 15px;
        }

        &.slack-notification,
        &.troubleshooting-badge,
        &.seo-badge {
          @media only screen and (max-width: 1024px) {
            display: none;
          }
          transition: all 1s ease-in-out;
          z-index: 3;
          position: absolute;
          bottom: -15%;
          right: -15%;
          height: auto;
          width: 500px;
          border-radius: 5px;

          -webkit-box-shadow: 0px 6px 24px 0px rgba($darkGrey, 0.15);
          -moz-box-shadow: 0px 6px 24px 0px rgba($darkGrey, 0.15);
          box-shadow: 0px 6px 24px 0px rgba($darkGrey, 0.15);
        }
        &.seo-back-img {
          filter: none;
          top: -150px;
          left: inherit;
          right: -10%;
        }
        &.seo-badge {
          width: 300px;
          left: -5%;
        }
        &.troubleshooting-badge {
          width: 200px;
          left: -5%;
          bottom: 90%;
          z-index: 3;
        }
        &.troubleshooting-back {
          width: 500px;
          height: auto;
          left: 20%;
          top: 80%;
          z-index: 3;
          filter: none;
        }
      }

      .img-main,
      video {
        position: relative;
        z-index: 2;
        max-width: 90%;
        border-radius: 15px;
        @media only screen and (max-width: 1024px) {
          max-width: 100%;
          margin-top: 40px;
        }
      }
    }
    .slack-notification {
      top: 80%;
    }
    &.slack:hover .slack-notification {
      transition: top 2s ease-in-out;
      top: 100%;
    }
  }
}
