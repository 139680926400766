// @import Swiper styles
@import "swiper/swiper.scss";
@import "swiper/components/navigation/navigation.scss";
@import "swiper/components/pagination/pagination.scss";
//@import "swiper/components/scrollbar/scrollbar.scss";

#GetStarted {
  @include pleft;

  height: 100%;
  margin: auto;
  margin-bottom: 200px;
  position: relative;

  .grid-svg-get-started {
    position: absolute;
    top: 0;
    width: 40%;
    left: 0;
    opacity: 0.5;
  }
  .gs-title {
    @include sectionTitle;
    color: $darkBlue;
    position: relative;

    .gs-svg {
      height: 250px;
      width: 250px;
      position: absolute;
      top: 0;
      right: 10%;
      transition: 2s ease-in-out;
      // initial 0
      .leftShape {
        transition: 2s ease-in-out;
        d: path(
          "M45.49 64.7C36.84 63.418 22.254 22.639 34.37 7.424 41.43-1.446 61.847-3.015 68.36 6.264c4.462 6.355-2.18 61.508-22.87 58.437z"
        );
      }
      .rightShape {
        transition: 2s ease-in-out;
        d: path(
          "M10.359 60.252c-7.146-10.366-16.73-35.32-4.48-37.504 11.8-2.105 58.475 40.586 38.077 55.106-13.95 9.93-26.422-7.193-33.597-17.602z"
        );
      }
      // initial 1
      &-1 {
        .leftShape {
          transition: 2s ease-in-out;
          d: path(
            "M19.046 62.956c-5.806-6.54 9.198-47.166 28.256-51.05 11.109-2.263 27.766 9.644 26.802 20.94-.66 7.737-41.169 45.75-55.058 30.11z"
          );
        }
        .rightShape {
          transition: 2s ease-in-out;
          d: path(
            "M.252 15.635C2.267 3.875 17.215-1.7 32.67.455c11.87 1.656 32.118 44.1 11.72 58.62C30.443 69.006-3.235 35.983.253 15.636z"
          );
        }
      }

      // initial 2
      &-2 {
        .leftShape {
          transition: 2s ease-in-out;
          d: path(
            "M31.848 40.091C27.49 34.802 39.293 4.93 52.74 1.475 60.632-.552 73.89 6.6 73.165 15.737c-.496 6.258-30.894 37.006-41.317 24.354z"
          );
        }
        .rightShape {
          transition: 2s ease-in-out;
          d: path(
            "M.647 12.934C4.713-.69 35.077-3.68 42.47 4.574c7.997 8.929 22.714 37.28 2.316 51.8-13.95 9.93-50.044-23.66-44.14-43.44z"
          );
        }
      }
    }
  }

  .gs-central {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    width: 100%;
    @media only screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }

    .gs-text {
      padding-bottom: 10%;
      width: 40%;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-direction: column;
      padding-right: 15px;
      @media only screen and (max-width: 1024px) {
        order: 2;
        width: 80%;
        margin: auto;
      }

      .gs-text-title {
        @include sectionSubTitle;
        color: $lightRed;
        padding-bottom: 20px;
      }
      .gs-text-desc {
        min-height: 200px;
        @media only screen and (max-width: 1024px) {
          min-height: 100px;
        }
        @include typoBody;
        color: $darkGrey;
        padding-bottom: 20px;
        font-weight: lighter;
      }
      .gs-text-cta {
        text-align: right;
        button {
          @include primaryButton;
          background: $lightRed;
          a {
            text-decoration: none;
            color : white;
          }
        }
      }
    }

    .slide-control {
      padding-top: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      @include typoBody;
      color: $lightRed;
      opacity: 0.8;
      .arrow-box {
        cursor: pointer;
        background: transparent;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
      // control the slider
      .slide-number {
        width: 50px;
        text-align: center;
        margin: 5px;
      }
    }
  }

  .swiper {
    @include width;
    position: relative;
    .swiper-pagination {
      bottom: 0px;
      text-align: left;
      padding-left: 50px;
    }
  }
  .slide {
    width: 100%;
    padding: 20px 0;
    img {
      -webkit-box-shadow: 0px 6px 14px 0px rgba($darkGrey, 0.15);
      -moz-box-shadow: 0px 6px 14px 0px rgba($darkGrey, 0.15);
      box-shadow: 0px 6px 14px 0px rgba($darkGrey, 0.15);
      border-radius: 15px;
      width: 100%;
    }
  }

  video {
    padding: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
  .gs-svg {
    height: 250px;
    width: 250px;
    position: absolute;
    bottom: 0;
    left: -1%;
    opacity: 0.6;
    transition: 2s ease-in-out;
    @media only screen and (max-width: 1024px) {
      top: -20% ;
      left: 20%;
    }
    // initial 0
    .leftShape {
      transition: 2s ease-in-out;
      d: path(
        "M45.49 64.7C36.84 63.418 22.254 22.639 34.37 7.424 41.43-1.446 61.847-3.015 68.36 6.264c4.462 6.355-2.18 61.508-22.87 58.437z"
      );
    }
    .rightShape {
      transition: 2s ease-in-out;
      d: path(
        "M10.359 60.252c-7.146-10.366-16.73-35.32-4.48-37.504 11.8-2.105 58.475 40.586 38.077 55.106-13.95 9.93-26.422-7.193-33.597-17.602z"
      );
    }
    // initial 1
    &-1 {
      .leftShape {
        transition: 2s ease-in-out;
        d: path(
          "M19.046 62.956c-5.806-6.54 9.198-47.166 28.256-51.05 11.109-2.263 27.766 9.644 26.802 20.94-.66 7.737-41.169 45.75-55.058 30.11z"
        );
      }
      .rightShape {
        transition: 2s ease-in-out;
        d: path(
          "M.252 15.635C2.267 3.875 17.215-1.7 32.67.455c11.87 1.656 32.118 44.1 11.72 58.62C30.443 69.006-3.235 35.983.253 15.636z"
        );
      }
    }

    // initial 2
    &-2 {
      .leftShape {
        transition: 2s ease-in-out;
        d: path(
          "M31.848 40.091C27.49 34.802 39.293 4.93 52.74 1.475 60.632-.552 73.89 6.6 73.165 15.737c-.496 6.258-30.894 37.006-41.317 24.354z"
        );
      }
      .rightShape {
        transition: 2s ease-in-out;
        d: path(
          "M.647 12.934C4.713-.69 35.077-3.68 42.47 4.574c7.997 8.929 22.714 37.28 2.316 51.8-13.95 9.93-50.044-23.66-44.14-43.44z"
        );
      }
    }
  }
}
