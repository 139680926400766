#Footer {
  background: rgba($darkBlue, 0.9);
  .footer-central {
    padding: 10px;
    color: $white;
    @include width;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    margin: auto;

    .col {
      min-width: 200px;
      min-height: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      height: auto;
      a.link {
        padding: 10px;
        width: 100%;
        text-decoration: none;
        @include typoBody;
        color: $white;
        &:before {
          content: " - ";
          font-size: 15px;
        }
      }
    }
  }
  .credit {
    padding: 20px;
    width: 100%;
    text-align: center;
    color: $white;
    font-size: 16px;
    .flaticon {
      font-size: 12px;
      color: $darkGrey;
      a {
        color: #e0e0e0;
        text-decoration: none;
        color: #efefef;

      }
    }
  }
}
