#UseCases {
  margin-top: 50px;
  padding-top: 100px;

  .uc-title {
    @include sectionTitle;
    color: $darkBlue;
    position: relative;
    @include pleft;
  }

  .ucs {
    padding-bottom: 100px;
    @media only screen and (max-width: 1024px) { 
      padding-bottom: 200px;

    }

    @include pleft;
    .uc {
      overflow: hidden;
      position: relative;
      padding: 30px 20px;
      border-radius: 10px;
      @include shadow($darkGrey);

      .card-title {
        @include sectionSubTitle;
        margin-bottom: 30px;
        color: $lightRed;
      }
      .card-body {
        width: 80%;
        @include typoBody;
        color: $darkGrey;
        font-weight: normal;
        margin-bottom: 30px;

        span {
          color: $darkBlue;
          font-weight: bold;
        }
      }

      .card-cta {
        @include primaryButton;
        font-size: 16px;
        color: $darkBlue;
        background: transparent;
        border: solid 2px $darkBlue;
        display: inline;
        &:hover {
          color: white;
          background: $darkBlue;
        }
      }
      .uc-svg {
        opacity: 0.4;
        height: 400px;
        width: 400px;
        position: absolute;
        bottom: -100px;
        right: -100px;
        @media only screen and (max-width: 1024px) {
          bottom: -50px;
          right: -50px;
          height: 200px;
          width: 200px;
        }
      }
    }
  }
}
